import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div className="container condensed padding">
            <Introduction />
            <InformationUse />
            <InformationCollection />
            <InformationProtection />
        </div>
    );
}

function Introduction() {
    return (
        <div>
            <h3 className="content-header">
                Introduction
            </h3>
            <p className="std-p">
                Nevelec is a small electrical company in business that provices electrical solutions. "Nevelec", or "we", or "us" refers
                to Nevelec and the Nevelec business.
            </p>
            <p className="std-p">
                This privacy policy applies whenever you visit nevelec.com.au and contact us for our services through the use of email 
                or the contact form. This privacy policy describes:
            </p>
            <ul className="std-p">
                <li>What information we collect and why</li>
                <li>How we collect your information</li>
                <li>How we protect your information you provide</li>
            </ul>
            <p className="std-p">
                If you do not wish for us to collect and store your information then you may not want to use our services.
            </p>
        </div>
    )
}

function InformationUse() {
    return (
        <div>
            <h3 className="content-header">
                Information We Collect And Why
            </h3>
            <p className="std-p">
                We collect all information that you provide us directly. This information is your name, email address, and any other contact
                information including but not limited to your contact phone number and home address. We also collect any information 
                relating your enquiry for our services.
            </p>
            <p className="std-p">
                This information is only used to contact you for our services and is not repurposed in any other way.
            </p>
        </div>
    )
}

function InformationCollection() {
    return (
        <div>
            <h3 className="content-header">
                How We Collect Your Information
            </h3>
            <p className="std-p">
                This information is only collected when you contact us directly via email or through the contact form in the 
                contact us page.
            </p>
        </div>
    )
}

function InformationProtection() {
    return (
        <div>
            <h3 className="content-header">
                How We Protect Your Information
            </h3>
            <p className="std-p">
                When we collect your information, it is transferred securly from your browser to our servers by using SSL certificates
                which encrypts all data, making it unreadable to any entity without the appropriate security keys.
            </p>
            <p className="std-p">
                Your information is then securely stored in our Google Suite which we use for managing business email transactions. Should 
                you wish to have your information deleted, please contact us directly.
            </p>
        </div>
    )
}
