import React from 'react';


export default function ErrorPage() {
    return (
        <div className="container padding">
            <h3 className="content-header">Page Doesn't Exist!</h3>
            <p className="std-p text-gray">
                It looks like the page you were searching for doesn't exist! You may want to recheck the URL.
            </p>
        </div>
    )
}