import React, { Component } from 'react';
import ContactUs from './Components/Contact/Contact';
import AboutUs from './Components/About/About';
import ErrorPage from './Components/Error/Error';
import OurServices from './Components/Services/Services';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import './App.css';
import { Route, Switch, Link } from 'react-router-dom';

class App extends Component {

    constructor(props) {
        super(props);
        this.handleNavigationHighlight = this.handleNavigationHighlight.bind(this);
    }

    handleNavigationHighlight() {
        const path = window.location.pathname.replace('/', '');

        const relPath = path === '' ? 'home' : path;
        let activeLink = document.querySelector('a.active');
        let link = document.querySelector(`[rel="${relPath}"]`);

        if (typeof activeLink !== undefined && activeLink !== null) {
            activeLink.classList.remove('active');
        }
        
        if (typeof link !== undefined && link !== null) {
            link.classList.add("active");
        }
    }

    componentDidMount() {
        this.handleNavigationHighlight();
    }

    componentDidUpdate() {
        this.handleNavigationHighlight();
    }

    render() {
        return (
            <div className="app">
                <Header />
                <main>
                    <Switch>
                        <Route path="/" exact component={MainHeader} />
                        <Route path="/about" exact component={AboutUs} />
                        <Route path="/services" exact component={OurServices} />
                        <Route path="/contact" exact component={ContactUs} />
                        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                        <Route path="/*" component={ErrorPage} />
                    </Switch>
                </main>
                <Footer />
            </div>
        );
    }
}

function Header() {
    return (
        <header>
            <div className="container">
                <div>
                    <HeaderLogo />
                </div>
                <div>
                    <HeaderMenu />
                </div>
            </div>
        </header>
    )
}

function HeaderLogo() {
    return (
        <Link to="/">
            <img id="site-logo" src="./images/logo.png" alt="Logo for nevelec" />
        </Link>
        
    );
}

function HeaderMenu() {

    return (
        <nav className="header-menu">
            <Link rel="home" to="/">Home</Link>
            <Link rel="about" to="/about">About Us</Link>
            <Link rel="services" to="/services">Our Services</Link>
            <Link rel="contact" to="/contact">Contact Us</Link>
        </nav>
    )
}

function MainHeader() {
    return (
        <section id="main-header">
            <div id="header-overlay"></div>
            <div className="header-content">
                <div id="titles">
                    <MainTitle />
                    <SubTitle />
                </div>
                <CallToAction />
            </div>
        </section>
    );
}

function MainTitle() {
    return (
        <h1 id="main-title">Providing quality electrical design and installation </h1>
    )
} 

function SubTitle() {
    return (
        <h4 id="sub-title">"Making modern affordable"</h4>
    )
} 

function CallToAction() {
    return (
        <div>
            <Link to='/contact' className="call-to-action bg-blue">Contact Us</Link>
        </div>
    )
}

function SocialMediaLinks() {
    return (
        <div id="social-media-links">
            <a href="https://www.facebook.com/Nevelec-1917206205053365" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/nevelec01/" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-instagram"></i>
            </a>
        </div>
    );
}

function Footer() {
    return (
        <footer>
            <div className="footer-content">
                <div className="footer-block contact-info">
                    <h3>Contact Us</h3>
                    <p id="postal-information">P.o.Box 331, Toowong 4066</p>
                    <p id="phone-information">0488 951 753</p>
                    <p id="email-information">info@nevelec.com.au</p>
                </div>
                <div className="footer-block">
                    <h3>Navigation</h3>
                    <nav>
                        <Link to="/">Home</Link>
                        <Link to="/about">About Us</Link>
                        <Link to="/services">Our Services</Link>
                        <Link to="/contact">Contact Us</Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </nav>
                </div>
                <div className="footer-block">
                    <h3>Follow Us</h3>
                    <SocialMediaLinks />
                </div>
                <div className="footer-block">
                    <div className="m-bottom">
                        <h3>ABN</h3>
                        <p>49629783188</p>
                    </div>
                    <div>
                        <h3>Electrical Licence</h3>
                        <p>85496</p>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <span>&copy; 2018 A.N. CONTRACTING Pty Ltd - designed by <a href="http://www.lachlanenderlin.com" rel="noopener noreferrer" target="_blank">Lachlan Enderlin</a></span>
            </div>
        </footer>
    );
} 

export default App;
