import React, { Component } from 'react';
import './About.css';

class AboutUs extends Component {

    render() {
        return (
            <div className="container condensed padding">
                <h1 className="content-header mc-header">About Us</h1>
                <p className="text-gray std-p">
                    Nevelec is an electrical company providing domestic and commercial design and installations. 
                    Operating since 2018 with its sole owner and operator, Aaron Neville, who has been working in the 
                    electrical industry for up to 10 years. Nevelec offers electrical solutions that competes with the 
                    ever-growing technological advances our industry is going through. As an aspiring new business in 
                    the industry, we thrive to deliver fast quality installations, which will leave clients with a 
                    complete understanding of their installation.
                </p>
            </div>
        )
    }
}

export default AboutUs;