import React from 'react';
import './Services.css';
import { Link } from 'react-router-dom';


export default function OurServices() {
    return (
        <div className="container condensed padding">
            <h3 className="content-header mc-header">Our Services</h3>
            <p className="std-p text-gray">
                Nevelec services the domestic and commercial industry. From new homes, renovations and add-ons to 
                architectural designed homes and commercial fit outs.
            </p>
            <div className="services">
                <Service image="electrical-electrician-electricity.jpg" alt="Person installing socket" service="Quality electrical installations" />
                <Service image="electrical-planning.jpg" alt="Electrical plans" service="Electrical design" />
                <Service image="satellite-dish.jpg" alt="Satellite dish on a roof" service="Communications installation" />
                <Service image="cables-close-up-computer.jpg" alt="Wall cable installation" service="Switchboard maintenance and upgrade" />
                <Service image="electrician.jpg" alt="Electritian inspecting switchboard" service="Routine inspections" />
            </div>
            <ContactNow />
        </div>
    );
}

function Service(props) {
    return (
        <div className="service">
            <div className="service-image">
                <img src={`./images/services/${props.image}`} alt={props.alt} height="100%" />
            </div>
            <div className="service-type text-gray">
                <span>{props.service}</span>
            </div>
        </div>
    )
}

function ContactNow() {
    return (
        <div className="services-contact-button">
            <Link className="contact-button bg-blue" to="/contact">
                Contact Now
            </Link>
        </div>
    )
}