import React, { Component } from 'react';
// import configProd from '../../config';
import './Contact.css';

class ContactUs extends Component {
    render() {
        return (
            <div className="container padding">
                <div className="spaced-flex">
                    <EnquiryForm />
                    <ContactInformation />
                </div>
            </div>
        )
    }
}

class EnquiryForm extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            message: ''
        };
        this.handleSubmission = this.handleSubmission.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.payloadCheck = this.payloadCheck.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        })
    }

    payloadCheck(name, email, message) {
        let nameError = document.getElementById('name-error');
        let emailError = document.getElementById('email-error');
        let messageError = document.getElementById('message-error');

        nameError.innerText = "";
        emailError.innerText = "";
        messageError.innerText = "";

        const maxLengthForMessage = 1000;

        if (name === '' || name.length <= 2) {
            nameError.innerText = "Name must have at least 2 characters";
            throw new Error("Name must have at least 2 characters");
        }
        
        if (email.length === 0) {
            emailError.innerText = "Please provide a valid email address";
            throw new Error("Please provide a valid email address");
        }
        
        if (email.indexOf('@') === -1) {
            emailError.innerText = "Please provide an email address";
            throw new Error("Please provide an email address");
        }

        if (message.length === 0) {
            messageError.innerText = "Message body must not be left blank";
            throw new Error("Message body must not be left blank");
        }
        
        if (message.length > maxLengthForMessage) {
            messageError.innerText = `Message body must be at most ${maxLengthForMessage} characters`;
            throw new Error(`Message body must be at most ${maxLengthForMessage} characters`);
        }
    }

    handleSubmission(event) {
        event.preventDefault();

        const name = this.state.name;
        const email = this.state.email;
        const message = this.state.message;

        let submitButton  = document.getElementById('submit-btn');
        
        let formError = document.getElementById('form-error');

        try {
            
            this.payloadCheck(name, email, message);
            
            submitButton.innerText = "Sending..."
            
            fetch(`api/email`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(this.state)
            }).then((response) => {
                console.log(response)
                
                if (!response.ok) {
                    throw Error(response.statusText);
                } else if (response.ok) {
                    submitButton.innerText = "Send";
                    document.getElementById("contact-form").reset();
                }

            }).catch((err) => {
                submitButton.innerText = "Try Again";
                formError.innerText = "Something went wrong. Was unable to send email.";
                console.error(err);
            })
        
        } catch(err) {
            submitButton.innerText = "Try Again";
            formError.innerText = "Something went wrong. Was unable to send email.";
            console.error(err);
        }

    }

    render() {
        return (
            <form id="contact-form" onSubmit={this.handleSubmission}>
                <div className="form-group">
                    <label className="text-gray">Name</label>
                    <span id="name-error" className="error-message"></span>
                    <input className="form-control" type="text" name="name" onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label className="text-gray">Email</label>
                    <span id="email-error" className="error-message"></span>
                    <input className="form-control" type="email" name="email" onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label className="text-gray">Message</label>
                    <span id="message-error" className="error-message"></span>
                    <textarea rows="8" className="form-control" name="message" onChange={this.handleChange}></textarea>
                </div>
                <div>
                    <span id="form-error" className="error-message"></span>
                </div>
                <div className="form-group form-buttons">
                    <button id="submit-btn" className="bg-blue">Send</button>
                </div>
            </form>
        );
    }
}

function ContactInformation() {
    return (
        <div id="contact-information">
            <h3 className="contact-header">Contact us now for your electrical solutions</h3>
            <div>
                <p className="contact-instructions std-p">Use the enquiry form to contact us and we'll get back to you within 24 hours. Alternatively you can reach us at:</p>
                <div className="contact-buttons">
                    <a className="contact-button bg-blue" href="mailto:info@nevelec.com.au">info@nevelec.com.au</a>
                    <a className="contact-button bg-blue" href="tel:0488951753">0488 951 753</a>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;